import React from 'react'
import '../css/Card.css'

export default function Card({ color, card, type }) {
  
  return (
    <div className="card" style={{backgroundColor: `${color}`,color: `white`}}>
        <div className="card-title">
            {card.title}
        </div>
        <div className="card-body">
            {card.content} 
        </div>
        <div className="card-actions">
        {
            card.links && Object.keys(card?.links).map((link, index)=>{
                    return <a key={index} target='new' className='card-link' href={card.links[link]}>{link.toUpperCase()}</a>
                }) 
        }
        

          
            
        </div>
    </div>
  )
}
