import { useState } from 'react'
import CardContainer from './components/CardContainer';

function App() {

  const colors = ["#A40E4C","#4D4D93", "#ACC3A6", "#F5D6BA", "#F49D6E"]

  let containers = {
    'JavaScript': [
      {title:'Personal React Site',content:'A React based site for display personal projects', links:{github:'https://github.com/nyzzik/personal-react-site'}},
      {title:'Nytro Launcher', content:'Modded Minecraft launcher for the modded network Nytro Networks. ', links:{github:'https://github.com/nyzzik/NytroLauncher'}}
    ],
    'Python':[
      {title:'dice-bot',content:'A discord bot that can assist with dice rolls when playing role-playing games.', links:{github:'https://github.com/nyzzik/dice-bot'}},
      {title:'quest-bot',content:'WIP discord bot that will allow users to choose classes and take on quests. ', links:{github:'https://github.com/nyzzik/quest-bot'}}
      
    ],
    'Java':[
      {title:'FRC PowerUp2018', content:'FRC Team6808 Robot Code for game PowerUp in 2018.', links:{github:'https://github.com/WTR6808/PowerUp2018'}},
      {title:'FRC DeepSpace2019', content:'FRC Team6808 Robot Code for game DeepSpace in 2019.', links:{github:'https://github.com/WTR6808/DeepSpace2019'}},
      {title:'FRC InfiniteRecharge2020', content:'FRC Team6808 Robot Code for game DeepSpace in 2020.', links:{github:'https://github.com/WTR6808/InfiniteRecharge2020'}}
    ],
    'C#':[
      {title:'Computing and Informatics Design Project', content:'2D platformer game created for CI102/CI103 using the Unity Game Engine in collaboration with my groupmates.'}
    ],
    'Other':[

    ]


  }

  let certs = {
    W3Schools: {
      JavaScript: {code:''}
    }
  }

  const [selector, setSelector] = useState(true);
  
  let genProjects = ()=>{
    return Object.keys(containers).map((key, index)=>{
      return (
      <CardContainer key={index} title={key} color={colors[index]} cards={containers[key]} type='project' /> 
      )
    })
  }

  let genCerts = ()=>{
    return Object.keys(certs).map((key, index)=>{
      return <CardContainer key={index} title={key} color={colors[index]} cards={certs[key]} type='cert' />
    })
  }

  let makeActive = (element)=>{
    let arr = document.querySelectorAll('.active')
    element.classList.add('active')
    for(let elm of arr){
      if(element===elm) continue
      elm.classList.remove('active')
    }
  }



  return (
    <>
      <header id="header">
        <img src="https://avatars.githubusercontent.com/u/24283788?s=230&v=4" alt=""></img>
        <h4>Josh Biedrzycki</h4>
        <h5>Software Engineer</h5>
      </header>
      <div id="selector-container">
        <div id="project-button" className='active' onClick={(e)=>{makeActive(e.target); setSelector(true); console.log(selector)}}>PROJECTS</div>
        <div id="cert-button" onClick={(e)=>{makeActive(e.target); setSelector(false); console.log(selector)}}>CERTIFICATIONS</div>
      </div>
      <div id="card-section">
      {
        selector ? 
        genProjects() :
        genCerts()
      }
      </div>
    </>
  );
}

export default App;
