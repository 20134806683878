import Card from './Card'
import '../css/CardContainer.css'

export default function CardContainer({cards, color, title, type}) {

    return (
        <div id="container">
            <h1 id="container-header" style={{color:`${color}`}}>{title}</h1>
            <div id="container-grid">
                {

                type==='project' && cards?.map((child, index)=>{
                    return <Card key={index} color={color} card={child} type={type}></Card>
                })

                }
                {
                 (type==='cert' && Object.keys(cards)?.map((cert, index)=>{
                    return <Card key={index} color={color} card={{title:cert, content:'Verification code: ' + cards[cert].code}} type={type}/>
                 }))


                }
            </div>
        </div>
    )
}
